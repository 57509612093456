exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2B1KKYXdZaXvfRL6{height:100vh;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;background:white;color:#424242;text-transform:uppercase}._2B1KKYXdZaXvfRL6 ._15LwAtDKT6EVyYKx{text-transform:uppercase}._34K-LFeYo9QWksma{font-size:10rem;margin:0 10px;font-weight:400}.EnfBWKU23qDCTyS2{margin:0;margin-bottom:25px;font-size:30px;font-weight:400}._2_J58LZJPZ1mK8Hu{margin:0}._3_kXyClUPKSF0Hk6{margin-top:30px}\n", ""]);

// Exports
exports.locals = {
	"wrapper": "_2B1KKYXdZaXvfRL6",
	"goHome": "_15LwAtDKT6EVyYKx",
	"errorCode": "_34K-LFeYo9QWksma",
	"title": "EnfBWKU23qDCTyS2",
	"errorMessage": "_2_J58LZJPZ1mK8Hu",
	"goHomeLink": "_3_kXyClUPKSF0Hk6"
};