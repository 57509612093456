exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3S2wuWHMn9B0W_Sl{display:none}._9lDgdUlFZLFz0AWZ{height:100%;width:100%;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;position:absolute;z-index:1500}._1FP1jifVaaExSLhU{background-color:rgba(0,0,0,0.2)}._2lOAvdr5fN1AxxMs{position:static}\n", ""]);

// Exports
exports.locals = {
	"wrapper": "_3S2wuWHMn9B0W_Sl",
	"isLoading": "_9lDgdUlFZLFz0AWZ",
	"backGround": "_1FP1jifVaaExSLhU",
	"notAbsolute": "_2lOAvdr5fN1AxxMs"
};