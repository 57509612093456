exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._7Dr-LnEZmljhLV0s{height:14.625rem;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center}._7Dr-LnEZmljhLV0s ._13coEgQ_6qw6TB2i{height:9.375rem;width:100%;border:1px dashed #e0e0e0;border-radius:3px;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;text-align:center}._7Dr-LnEZmljhLV0s ._13coEgQ_6qw6TB2i:hover{cursor:pointer}._7Dr-LnEZmljhLV0s ._13coEgQ_6qw6TB2i ._24B7f5NtrGWPYv0C{font-size:.875rem;padding:.625rem;color:#c4c4c4}._7Dr-LnEZmljhLV0s ._13coEgQ_6qw6TB2i ._1_L852P-JhpEgLTp{max-width:18.75rem;overflow:hidden;-o-text-overflow:ellipsis;text-overflow:ellipsis}._1tyBMeMkNrfeduOt{display:none}@media screen and (max-width: 768px){._7Dr-LnEZmljhLV0s{height:23.9375rem}._7Dr-LnEZmljhLV0s ._13coEgQ_6qw6TB2i{height:18.75rem}._7Dr-LnEZmljhLV0s ._13coEgQ_6qw6TB2i ._1_L852P-JhpEgLTp{max-width:12.5rem}}\n", ""]);

// Exports
exports.locals = {
	"addFileUpload": "_7Dr-LnEZmljhLV0s",
	"uploadFile": "_13coEgQ_6qw6TB2i",
	"dragDropTitle": "_24B7f5NtrGWPYv0C",
	"nameOfFile": "_1_L852P-JhpEgLTp",
	"fileInput": "_1tyBMeMkNrfeduOt"
};