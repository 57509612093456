exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3wvtdlDTTIIlaMvX{min-height:calc(100% - 60px);width:calc(100% - 60px);padding:30px;margin:30px;background:white;position:relative}._3wvtdlDTTIIlaMvX ._1-SU3JkPrQ-OUI6G{display:-ms-flexbox;display:flex;border-bottom:1px solid rgba(196,196,196,0.35)}._3wvtdlDTTIIlaMvX ._1-SU3JkPrQ-OUI6G ._3Yju28gSqTDyp5iK button{padding:1rem .75rem;min-width:8.75rem}._3wvtdlDTTIIlaMvX ._1-SU3JkPrQ-OUI6G ._3Yju28gSqTDyp5iK span{text-transform:none;font-weight:400;font-size:.875rem}._3wvtdlDTTIIlaMvX ._1-SU3JkPrQ-OUI6G .yfUVIaSgfnlX1T0X{color:#757575 !important}._3wvtdlDTTIIlaMvX ._1-SU3JkPrQ-OUI6G .yfUVIaSgfnlX1T0X span span svg path{fill:#757575 !important}._3wvtdlDTTIIlaMvX ._1-SU3JkPrQ-OUI6G ._1bq797W3LIHv_8f-{margin-top:auto;margin-left:auto;margin-bottom:.625rem;font-weight:400;font-size:.875rem;text-transform:none;color:#d72e3d;opacity:0.9}._3wvtdlDTTIIlaMvX ._1-SU3JkPrQ-OUI6G ._1bq797W3LIHv_8f-:hover{background-color:white !important}._3wvtdlDTTIIlaMvX ._1-SU3JkPrQ-OUI6G ._1bq797W3LIHv_8f- span span{margin-right:.6875rem}._3wvtdlDTTIIlaMvX ._1-SU3JkPrQ-OUI6G ._1bq797W3LIHv_8f- span span svg{width:.75rem;height:.75rem}@media screen and (max-width: 1200px){._3wvtdlDTTIIlaMvX{padding:15px;margin:15px;min-height:calc(100% - 30px);width:calc(100% - 30px)}}@media screen and (max-width: 960px){._3wvtdlDTTIIlaMvX ._1-SU3JkPrQ-OUI6G ._3Yju28gSqTDyp5iK button{min-width:6.25rem;padding:1rem .5rem}}@media screen and (max-width: 500px){._3wvtdlDTTIIlaMvX{padding:10px}._3wvtdlDTTIIlaMvX ._1-SU3JkPrQ-OUI6G ._3Yju28gSqTDyp5iK button{min-width:4.0625rem;padding:1rem .375rem}._3wvtdlDTTIIlaMvX ._1-SU3JkPrQ-OUI6G ._1bq797W3LIHv_8f-{padding-left:0px;padding-right:0px}._3wvtdlDTTIIlaMvX ._1-SU3JkPrQ-OUI6G ._1bq797W3LIHv_8f- span span svg{display:none}}\n", ""]);

// Exports
exports.locals = {
	"wrapper": "_3wvtdlDTTIIlaMvX",
	"headerSettings": "_1-SU3JkPrQ-OUI6G",
	"settingsTabs": "_3Yju28gSqTDyp5iK",
	"disableBtn": "yfUVIaSgfnlX1T0X",
	"closeAccountBtn": "_1bq797W3LIHv_8f-"
};