exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".jREsss4QqWaWXzY3>div:nth-child(3){margin-right:.625rem;overflow:visible}.jREsss4QqWaWXzY3 .jREsss4QqWaWXzY3{border-radius:3px;width:14.875rem}.jREsss4QqWaWXzY3 .jREsss4QqWaWXzY3 ._1U6c93ZtwSKHzDuO{width:0;height:0;border-top:8px solid transparent;border-right:10px solid white;border-bottom:8px solid transparent;position:absolute;top:48px;-webkit-transform:rotate(-180deg);-ms-transform:rotate(-180deg);transform:rotate(-180deg);right:-10px}.jREsss4QqWaWXzY3 .jREsss4QqWaWXzY3 ._9EP19tTPVHCH6ZDA{margin:0;padding:.5625rem 1.125rem;font-size:.875rem;color:#424242;background:#eeeeee;border-radius:2px 2px 0px 0px}.jREsss4QqWaWXzY3 .jREsss4QqWaWXzY3 ._1aWaa6u8ScZs03v0{margin:0;padding:.625rem 0 .625rem .5rem;height:10.3125rem}.jREsss4QqWaWXzY3 .jREsss4QqWaWXzY3 ._1aWaa6u8ScZs03v0>div>div:first-child{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;padding-left:12px}.jREsss4QqWaWXzY3 .jREsss4QqWaWXzY3 ._1aWaa6u8ScZs03v0>div>div:first-child .agTnhKXiATYn57Wk>span:first-child{padding:.5625rem}.jREsss4QqWaWXzY3 .jREsss4QqWaWXzY3 ._1aWaa6u8ScZs03v0>div>div:first-child .agTnhKXiATYn57Wk>span:last-child{font-weight:400;font-size:.875rem;color:#757575;margin-left:.3125rem;max-width:8.4375rem;overflow:hidden;-o-text-overflow:ellipsis;text-overflow:ellipsis}@media screen and (max-width: 500px){.jREsss4QqWaWXzY3 .jREsss4QqWaWXzY3 ._1U6c93ZtwSKHzDuO{display:none}}\n", ""]);

// Exports
exports.locals = {
	"paperNotifiedOfUpdates": "jREsss4QqWaWXzY3",
	"triangle": "_1U6c93ZtwSKHzDuO",
	"notifiedOfUpdatesLabel": "_9EP19tTPVHCH6ZDA",
	"categoriesContent": "_1aWaa6u8ScZs03v0",
	"formControlCategories": "agTnhKXiATYn57Wk"
};